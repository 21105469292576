import React, { Component } from 'react'
import ProgressLoader from './loader/ProgressLoader';
import axios from '../helper/Axios';

//assets
import line_logo from '../../static/images/line-logo.png';
import server_error from '../../static/images/server_error.svg';
import image_url_map from '../../static/images/constants/image_constants';
const amber_hi = image_url_map['amber_hi'];

class LineManage extends Component {
    state={
        request_status: 0 //0 - Placing request, 1- Success, 2 - error
    }

    componentDidMount() {
        this.makeOptRquest(1)
    }

    makeOptRquest = async(opt_status) => {
        let {profile} = this.state
        try{
            const response = await axios.post(`v1/users/line/manage_notification?is_accessible=true`, {opt_status})

            if(response && response.status === 200) {
                if(response.data.login_url) {
                    window.location.href = (response.data.login_url)
                } else {
                    this.setState({request_status: 1})
                }
            }
        } catch {
            this.setState({request_status: 2})
        }
    }

    render() {
        const { request_status } = this.state
        return (
            <>
            <div className="content whatsapp-content has-text-centered columns pd-top-75">
                {
                    request_status === 0 ?
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <p className="mg-bottom-10">Please wait while we are raising your request...</p>
                            <ProgressLoader />
                        </div>
                    </div>
                    : 
                    request_status !== 2 ?
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <img width="55%" src={line_logo} alt = "line"/>
                            <p className="is-size-4 mg-bottom-15 has-text-weight-bold">Thank you!</p>
                            <p>
                            You have successfully subscribed to receive notifications and updates from Amber on your LINE account.
                            </p>
                        </div>
                    </div> : 
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <img width="17%" src={server_error} alt = "whatsapp"/>
                            <p className="is-size-4 mg-bottom-15 has-text-weight-bold">Sorry!</p>
                            <p>
                                It's not you.. Its's us.<br/>
                                We're experiencing an <strong>internal server problem</strong>.
                                Please try again later or contact <a className="has-text-info" href="mailto:support@infeedo.com">support@infeedo.com</a>
                            </p>
                        </div>
                    </div>
                }
            </div>
            <img className="amber-hi" src={amber_hi} alt = "whatsapp"/>
            </>
        )
    }
}

export default LineManage